<template>
  <div
    class="base-image-input"
    :style="{ 'background-image': `url(${$helpers.imageHelper(imageData)})` }"
    @click="chooseImage"
  >
    <span
      v-if="!imageData && showText"
      class="placeholder"
    > {{ $t('Choose an Image') }} {{ $t('Recommended size is') + " " + aspectRatio }}</span>
    <input
      ref="fileInput"
      class="file-input"
      accept="image/*"
      type="file"
      @input="onSelectFile"
    >
    <b-modal
      id="cropperModal"
      v-model="showCropper"
      :title="$t('Crop the image')"
      size="xl"
      :no-close-on-backdrop="true"
      @ok="makeCrop"
      @hidden="showCropper = false"
    >
      <div class="cropper-wrapper-container">
        <img
          :id="cropperId"
          class="image-cropper-info"
          :src="blobUrl"
        >
      </div>
      <!-- <croppa
        ref="vueCroppaRef"
        v-model="vueCroppa"
        :width="760"
        :height="500"
        :initial-image="croppaImage"
        :placeholder-font-size="16"
        canvas-color="transparent"
        :show-remove-button="false"
        remove-button-color="black"
        :show-loading="true"
        :loading-size="50"
      /> -->
      <!-- <div class="col-md-12 d-flex justify-content-between">
        <button
          class="btn btn-primary"
          @click="$refs.vueCroppaRef.rotate()"
        >
          Totate 90eg
        </button>
        <button
          class="btn btn-primary"
          @click="$refs.vueCroppaRef.rotate(2)"
        >
          Rotate 180deg
        </button>
        <button
          class="btn btn-primary"
          @click="$refs.vueCroppaRef.rotate(-1)"
        >
          Rotate -90deg
        </button>
        <button
          class="btn btn-primary"
          @click="$refs.vueCroppaRef.flipX()"
        >
          Flip horizontally
        </button>
        <button
          class="btn btn-primary"
          @click="$refs.vueCroppaRef.flipY()"
        >
          Flip vertically
        </button>
      </div> -->
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'

export default {
  components: {
    BModal,
  },
  props: {
    defaultImage: {
      type: [String, Array, Object, File, Blob],
      default: null,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: String,
      default: "200 x 200",
      required: false,
    },
  },
  data() {
    return {
      croppaImage: '',
      imageData: this.defaultImage,
      uploadedFile: null,
      showCropper: false,
      cropperInit: null,
      vueCroppa: null,
      blobUrl: null,
      cropperId: `cropper_${Math.ceil(Math.random() * 1000000)}`,
    }
  },
  watch: {
    defaultImage: {
      immediate: true,
      handler(currentValue) {
        this.imageData = currentValue
      },
    },
  },
  methods: {
    cropVueCroppa() {
      if (this.$refs.vueCroppaRef) {
        this.$refs.vueCroppaRef.canvas.toBlob(blob => {
          this.uploadedFile = blob
          this.$emit('input-file', this.uploadedFile)
        })
      }
    },
    makeCrop() {
      if (this.cropperInit) {
        if (this.cropperInit.getCroppedCanvas({ width: 800, height: 800 }) != null) {
          const canvas = this.cropperInit.getCroppedCanvas({
            width: 800,
            height: 800,
          })

          this.imageData = canvas.toDataURL()
          canvas.toBlob(blob => {
            this.uploadedFile = blob
            this.$emit('input-file', this.uploadedFile)
          })
        }
        this.cropperInit.destroy()
      }
    },
    createCropper() {
      this.cropperInit = new Cropper(document.getElementById(this.cropperId), {
        dragMode: 'move',
      })
    },
    chooseImage() {
      this.$refs.fileInput.click()
    },
    onSelectFile() {
      const input = this.$refs.fileInput
      const { files } = input
      if (files && files[0]) {
        const reader = new FileReader()
        this.blobUrl = URL.createObjectURL(files[0])
        this.croppaImage = this.blobUrl
        reader.onload = e => {
          this.imageData = e.target.result
          this.blobUrl = reader.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.uploadedFile = files[0]
        reader.readAsDataURL(files[0])
        this.$emit('input-file', files[0])
        this.showCropper = true
        this.$nextTick(() => {
          this.createCropper()
          // window.vueCroppa = this.$refs.vueCroppaRef
        })
      } else {
        this.imageData = null
        this.$emit('input-file', null)
      }
    },
  },
}
</script>

<style scoped>
.base-image-input {
  display: block;
  width: 100%;
  height: 400px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  border: 1px solid #333;
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  max-height: 200px;
  font-family: 'Lato', 'Roboto Slab';
}

.base-image-input.is-invalid {
  border-color: #ea5455 !important;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: 'Lato', 'Roboto Slab';
  text-align: center;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.image-cropper-info {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .base-image-input {
    width: 100%;
    height: 200px;
  }
}
</style>
